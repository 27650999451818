import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetWarehouseFlows } from '@/redux/warehouseFlows/warehouseFlows.slice';
import { resetPickingData } from '@/redux/picking/picking.slice';

const ICON = '<';
const BackButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    // reset warehouseFlowStep and storedBarcode
    dispatch(resetWarehouseFlows());
    dispatch(resetPickingData());

    // 👇️ replace set to true
    navigate(-1);
  };
  return (
    <button className="c-back-button" onClick={handleClick} type="button">
      {' '}
      {ICON}{' '}
    </button>
  );
};

export default BackButton;
