import { Outlet, useLocation } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Header from './Header';

const handleRefresh = () => window.location.reload(true);

const WithHeaderLayout = () => {
  const location = useLocation();
  return (
    <>
      <Header />
      <PullToRefresh
        onRefresh={handleRefresh}
        pullingContent="miam miam miam miam"
        refreshingContent="🥝🥕🥦🍒"
        isPullable={
          !location.pathname.includes('warehouseflows') &&
          !location.pathname.includes('picking') &&
          !location.pathname.includes('inspectmob')
        }
      >
        <Outlet />
      </PullToRefresh>
    </>
  );
};

export default WithHeaderLayout;
