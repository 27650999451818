/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const MODES = [
  {
    icon: '🛒',
    id: 'supply',
    name: 'grocery',
    isVisible: true
  },
  { icon: '🔎', id: 'fill', name: 'basket', isVisible: true },
  { icon: '🖨️', id: 'print', name: 'print_label', isVisible: true },
  // { icon: '🛠', id: 'settings', name: 'settings', isVisible: true },
  {
    icon: '🔑',
    id: 'admin',
    name: 'admin',
    isVisible: false
  },
  { icon: '📝', id: 'restock', name: 'restock', isVisible: true },
  {
    icon: '🔄',
    id: 'warehouseflows',
    name: 'warehouse_flows',
    isVisible: true
  },
  { icon: '📦', id: 'boxreturn', name: 'box_return', isVisible: true },
  { icon: '📥', id: 'pickingv2', name: 'pickingv2', isVisible: true },
  { icon: '📨', id: 'fillv2', name: 'fillv2', isVisible: true },
  { icon: '🧠', id: 'printlabelsfc', name: 'printlabelsfc', isVisible: true },
  {
    icon: '❓',
    id: 'missingproducts',
    name: 'missingproducts',
    isVisible: true
  },
  {
    icon: '🚛',
    id: 'shipbox',
    name: 'shipbox',
    isVisible: true
  },
  {
    icon: '👀',
    id: 'inspectmob',
    name: 'inspectmob',
    isVisible: true
  }
];

function getInitialSelectedMode() {
  const { hash } = window.location;

  if (hash.includes('inspectmob')) {
    return MODES[12];
  }
  if (hash.includes('shipbox')) {
    return MODES[11];
  }
  if (hash.includes('missingproducts')) {
    return MODES[10];
  }
  if (hash.includes('printlabelsfc')) {
    return MODES[9];
  }
  if (hash.includes('pickingv2')) {
    return MODES[8];
  }
  if (hash.includes('fillv2')) {
    return MODES[7];
  }
  if (hash.includes('boxreturn')) {
    return MODES[6];
  }
  if (hash.includes('warehouseflows')) {
    return MODES[5];
  }
  if (hash.includes('restock')) {
    return MODES[4];
  }
  if (hash.includes('admin')) {
    return MODES[3];
  }
  if (hash.includes('print')) {
    return MODES[2];
  }
  if (hash.includes('fill')) {
    return MODES[1];
  }
  if (hash.includes('supply')) {
    return MODES[0];
  }

  return MODES[0];
}

const initialState = {
  data: MODES,
  selectedMode: getInitialSelectedMode()
};

const modeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    setMode: (state, { payload }) => {
      state.selectedMode = payload;
    }
  }
});

export const { setMode } = modeSlice.actions;

export default modeSlice.reducer;
