import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getBatch,
  closeBatch,
  getBatchs,
  updateMoveLine
} from '@/redux/restock/restock.actions';
import { selectUser } from '@/redux/user/user.slice';
import {
  BackButton,
  Button,
  FlashScreen,
  Input,
  Loader,
  Modal,
  ScanContainer
} from '@/components';
import {
  updateRestockStep,
  resetRestock,
  resetCurrentBatch
} from '@/redux/restock/restock.slice';
import { resetLocalStorage } from '@/helpers/helpers';
import { validateScan } from '@/redux/barcode/barcode.actions';
import missingProductIcon from '@/assets/restock_missing.png';
import { toggleModal } from '@/redux/modal/modal.slice';

function BatchDetails() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: userID } = useSelector(selectUser);
  const { currentBatch, batchs, restockStep } = useSelector(
    (state) => state.restock
  );
  const savedProductIndex = JSON.parse(
    localStorage.getItem(`productIndex_${userID}`)
  );

  const savedStep = JSON.parse(localStorage.getItem(`restockStep_${userID}`));

  // product to store
  const productToStore = JSON.parse(
    localStorage.getItem(`productsStored_${userID}`)
  );

  // products_name of products not stored
  const productNotStored = JSON.parse(
    localStorage.getItem(`productsMag_${userID}`)
  )?.map((product) => product.product_name);

  // get all products save in the local storage
  const productsMag = JSON.parse(localStorage.getItem(`productsMag_${userID}`));

  console.log({ currentBatch, userID });

  const [productIndex, setProductIndex] = React.useState(
    savedProductIndex || 0
  );
  const [redirectUserToMagZone, setRedirectUserToMagZone] = useState(false);
  const [requestedQty, setRequestedQty] = useState(null);
  const [inputtedQty, setInputtedQty] = useState(null);
  const [qtyError, setQtyError] = useState(false);
  const { isScanValid, message } = useSelector((state) => state.barcode);
  const savedBatchState = JSON.parse(
    localStorage.getItem(`batchState_${userID}`)
  );
  const [currentBatchState, setCurrentBatchState] = useState(
    savedBatchState || currentBatch.data
  );

  useEffect(() => {
    if (currentBatchState?.move_lines[productIndex]?.requested_qty) {
      setRequestedQty(currentBatchState.move_lines[productIndex].requested_qty);
    }
  }, [productIndex]);

  useEffect(() => {
    if (
      currentBatchState?.move_lines[productIndex]?.requested_qty &&
      requestedQty === null
    ) {
      setRequestedQty(currentBatchState.move_lines[productIndex].requested_qty);
    }
  }, [currentBatchState]);

  useEffect(() => {
    if (!currentBatch.data)
      dispatch(getBatch({ batchId: param.batchId, employeeId: userID }));
  }, [param.batchId, userID, productIndex]);

  const stateReseter = async () => {
    await dispatch(resetRestock());
    await resetLocalStorage(userID);
    dispatch(updateRestockStep({ restockStep: 'scan_product' }));
  };

  useEffect(() => {
    if (currentBatch.status === 'succeeded') {
      dispatch(getBatchs(userID));
    } else if (currentBatch.status === 'failed') {
      stateReseter();
      navigate('/restock');
    }
  }, [currentBatch]);

  useEffect(() => {
    setCurrentBatchState(currentBatch.data);
  }, [currentBatch.data, batchs.data]);

  // Saving productIndex to localStorage every time it changes
  useEffect(() => {
    localStorage.setItem(
      `productIndex_${userID}`,
      JSON.stringify(productIndex)
    );
  }, [productIndex]);

  useEffect(() => {
    if (savedStep) {
      dispatch(updateRestockStep({ restockStep: savedStep }));
    }
  }, []);

  const handleCloseBatch = async () => {
    resetLocalStorage(userID);
    resetRestock();
    localStorage.setItem(`restockStep_${userID}`, JSON.stringify(restockStep));

    dispatch(getBatchs(userID));

    navigate('/restock');
  };

  // store restockStep in localStorage in useEffect
  useEffect(() => {
    localStorage.setItem(`restockStep_${userID}`, JSON.stringify(restockStep));
  }, [restockStep]);

  const handleValueChange = (onChangeValue) => {
    const updatedBatch = JSON.parse(JSON.stringify(currentBatchState));

    if (updatedBatch.move_lines && updatedBatch.move_lines[productIndex]) {
      updatedBatch.move_lines[productIndex].qty_done = Number(onChangeValue);
      setCurrentBatchState(updatedBatch);
    }
    setInputtedQty(onChangeValue);
  };

  function updateBatchState(updatedBatchState, isMissingProduct) {
    const updatedLine = { ...updatedBatchState.move_lines[productIndex] };
    updatedLine.qty_done = Number(
      inputtedQty !== null ? inputtedQty : requestedQty
    );
    updatedBatchState.move_lines[productIndex] = updatedLine;

    setCurrentBatchState(updatedBatchState);
    localStorage.setItem(
      `batchState_${userID}`,
      JSON.stringify(updatedBatchState)
    );
    localStorage.setItem(
      `productsMag_${userID}`,
      JSON.stringify(updatedBatchState.move_lines)
    );

    if (productIndex + 1 < currentBatchState.move_lines?.length) {
      if (isMissingProduct === undefined) setProductIndex(productIndex + 1);
      dispatch(updateRestockStep({ restockStep: 'scan_product' }));
    } else {
      setProductIndex(0);
      setRedirectUserToMagZone(true);
    }
  }

  const handleClick = (isMissingProduct) => {
    const updatedBatchState = JSON.parse(JSON.stringify(currentBatchState));

    if (inputtedQty !== null) {
      if (inputtedQty <= requestedQty) {
        if (updatedBatchState?.move_lines[productIndex]) {
          updateBatchState(updatedBatchState);
          setInputtedQty(null);
        }
      } else if (inputtedQty > requestedQty) {
        setQtyError(true);
        setTimeout(() => {
          setQtyError(false);
        }, 3000);
      }
    } else if (inputtedQty === null) {
      updateBatchState(updatedBatchState, isMissingProduct);
    }
  };
  const handleClickMagZone = async () => {
    const updatedBatchState = JSON.parse(JSON.stringify(currentBatchState));
    setRedirectUserToMagZone(false);
    await dispatch(getBatch({ batchId: param.batchId, employeeId: userID }));

    await dispatch(updateRestockStep({ restockStep: 'list_products_magzone' }));
  };

  const resolveExpectedValue = () => {
    if (
      restockStep === 'scan_product' ||
      restockStep === 'quantities_input' ||
      restockStep === 'scan_product_magzone'
    )
      return currentBatchState.move_lines[productIndex]?.product_barcode;

    if (restockStep === 'list_products_magzone') return productsMag;

    if (restockStep === 'scan_mag') return productToStore.dest_barcode;

    return currentBatchState.move_lines[productIndex]?.dest_name;
  };

  const resolveButton = () => {
    if (redirectUserToMagZone)
      return (
        <Button
          onClick={handleClickMagZone}
          isPrimary
          title={t('views.restock.go_magzone')}
        />
      );

    if (restockStep === 'quantities_input')
      return (
        <Button
          onClick={() => handleClick()}
          isPrimary
          title={t('views.restock.validate_quantity')}
        />
      );
    if (restockStep === 'scan_mag')
      return <div className="u-scan">{t('views.restock.scan_mag')}</div>;

    if (restockStep === 'list_products_magzone')
      return <div className="u-scan">{t('views.restock.scan_cart')}</div>;

    if (restockStep === 'scan_product')
      return <div className="u-scan">{t('views.restock.scan_product')}</div>;

    if (restockStep === 'job_completed')
      return (
        <Button
          onClick={handleCloseBatch}
          isPrimary
          title={t('views.restock.close_batch')}
        />
      );

    return (
      <div className="u-scan">{t('views.restock.scan_product_magzone')}</div>
    );
  };

  // order products by ascending order

  const list = currentBatchState?.move_lines?.map((product) => [
    product.dest_name.substr(4),
    product.product_name
  ]);
  const sortedUniqueSequences = Array.from(
    new Set(list?.map((item) => item[0]))
  ).sort((a, b) => {
    const [aFirstPart, aSecondPart] = a.split('-');
    const [bFirstPart, bSecondPart] = b.split('-');

    if (aFirstPart !== bFirstPart) {
      return aFirstPart.localeCompare(bFirstPart);
    }
    return aSecondPart.localeCompare(bSecondPart);
  });

  if (currentBatch?.status !== 'succeeded' || !currentBatchState?.batch_id) {
    return (
      <div className="u-page-center">
        <Loader />
      </div>
    );
  }

  if (currentBatchState?.move_lines?.length === 0) {
    return (
      <div className="u-page-center">{t('views.restock.no_move_line')}</div>
    );
  }

  const handleMissingProductConfirm = async () => {
    handleClick(true);

    const updatedBatchState = JSON.parse(JSON.stringify(currentBatchState));

    const res = await dispatch(
      updateMoveLine({
        moveLineId: updatedBatchState.move_lines[productIndex].move_line_id,
        batchId: currentBatch?.data?.batch_id,
        employeeId: userID,
        productId: updatedBatchState.move_lines[productIndex].product_id,
        qtyDone: updatedBatchState.move_lines[productIndex].qty_done
      })
    );
    dispatch(toggleModal(false));

    // Check if productsMag_ exists in local storage before filtering
    const localStorageProductsMag = JSON.parse(
      localStorage.getItem(`productsMag_${userID}`)
    );
    if (localStorageProductsMag) {
      const updatedProductsMag = localStorageProductsMag.filter(
        (product) =>
          product.move_line_id !==
          updatedBatchState.move_lines[productIndex].move_line_id
      );
      localStorage.setItem(
        `productsMag_${userID}`,
        JSON.stringify(updatedProductsMag)
      );
      if (productIndex + 1 !== updatedBatchState.move_lines.length)
        dispatch(getBatch({ batchId: param.batchId, employeeId: userID }));
    } else {
      console.log('productsMag_ not found in local storage');
    }
  };

  const modalContent = () => (
    <div className="missing-product-modal-content">
      <img src={missingProductIcon} alt="" />
      <span>{t('views.restock.product_not_found')}</span>
      <div className="missing-product-modal-content__button-container">
        <div className="cancel" onClick={() => dispatch(toggleModal(false))}>
          {t('views.restock.cancel')}
        </div>
        <div className="confirm" onClick={() => handleMissingProductConfirm()}>
          {t('views.restock.confirm')}
        </div>
      </div>
    </div>
  );

  return (
    <div className="batch-details">
      <Modal>{modalContent()}</Modal>
      <div className="back-button-wrapper">
        <BackButton />
      </div>
      {restockStep === 'scan_product' && !redirectUserToMagZone && (
        <div
          className="missing-product-toggler"
          onClick={() => dispatch(toggleModal(true))}
        >
          <img src={missingProductIcon} alt="missing product button" />
        </div>
      )}

      <ScanContainer
        type="restock_product"
        expectedValue={resolveExpectedValue()}
      >
        {!redirectUserToMagZone &&
          restockStep !== 'list_products_magzone' &&
          restockStep !== 'job_completed' && (
            <div className="header-wrapper">
              <span>
                {t('views.restock.batch')} {param.batchId}{' '}
                {restockStep === 'scan_product' ||
                restockStep === 'quantities_input'
                  ? batchs.data &&
                    batchs.data?.length > 0 &&
                    t(
                      `views.restock.batch_state.${
                        batchs?.data?.find(
                          (batch) => batch.id === parseInt(param.batchId)
                        ).state
                      }`
                    )
                  : t('views.restock.product_to_mag')}
              </span>
              <span>
                {t('views.restock.product')}
                {restockStep === 'scan_mag' &&
                currentBatchState.move_lines &&
                productNotStored
                  ? ` ${
                      currentBatchState.move_lines.length -
                      productNotStored.length
                    } / `
                  : ` ${productIndex + 1} / `}
                {currentBatchState.move_lines?.length}
              </span>
            </div>
          )}

        {restockStep === 'scan_product' && !redirectUserToMagZone && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.product_source')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.source_name
              }
            />
            <span className="job-infos_header">
              {t('views.restock.product_available')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.product_name
              }
            />
            <span className="job-infos_header">
              {t('views.restock.barcode')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.product_barcode
              }
            />
          </div>
        )}

        {restockStep === 'quantities_input' && !redirectUserToMagZone && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.quantity_to_add')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.requested_qty
              }
            />

            <span className="job-infos_header">
              {t('views.restock.quantity_added')}
            </span>
            <Input
              editable
              initialValue={requestedQty}
              onChange={handleValueChange}
              type="number"
            />
          </div>
        )}

        {restockStep === 'scan_product_magzone' && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.product_to_move')}
            </span>

            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.product_name
              }
            />
            <span className="job-infos_header">
              {t('views.restock.product_destination')}
            </span>
            <Input
              editable={false}
              initialValue={
                currentBatchState?.move_lines[productIndex]?.dest_name
              }
            />
          </div>
        )}

        {/* List all products in ascending order */}
        {restockStep === 'list_products_magzone' && (
          <div>
            <div className="button-container">{resolveButton()}</div>

            <div className="list-products">
              {sortedUniqueSequences?.map((sequence) => (
                <div>
                  <h2>MAG-{sequence}</h2>
                  <ul style={{ marginBottom: '25px' }}>
                    {list
                      .filter((item) => item[0] === sequence)
                      ?.map((item) => (
                        <li
                          style={{
                            listStyle: 'none',
                            margin: '0 0 15px -15px'
                          }}
                        >
                          {!productNotStored?.includes(item[1]) ? '✅ ' : '🔳 '}{' '}
                          {item[1]}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}

        {restockStep === 'scan_mag' && !redirectUserToMagZone && (
          <div className="job-infos">
            <span className="job-infos_header">
              {t('views.restock.product_to_move')}
            </span>

            <Input
              editable={false}
              initialValue={productToStore?.product_name}
            />
            <span className="job-infos_header">
              {t('views.restock.product_destination')}
            </span>
            <Input editable={false} initialValue={productToStore?.dest_name} />
            <span className="job-infos_header">
              {t('views.restock.barcode')}
            </span>
            <Input
              editable={false}
              initialValue={productToStore?.dest_barcode}
            />
          </div>
        )}

        {redirectUserToMagZone && (
          <div className="restock-info-container">
            {t('views.restock.redirect_user_magzone')}
          </div>
        )}

        {restockStep === 'job_completed' && (
          <div className="restock-info-container">
            {t('views.restock.job_completed')}
          </div>
        )}

        {(restockStep === 'quantities_input' ||
          restockStep === 'scan_product_magzone' ||
          restockStep === 'scan_mag' ||
          restockStep === 'scan_product' ||
          restockStep === 'job_completed' ||
          redirectUserToMagZone) && (
          <div className="button-container">{resolveButton()}</div>
        )}

        {isScanValid === false && (
          <FlashScreen type="error" message={t(message)} />
        )}
        {qtyError && (
          <FlashScreen type="error" message={t('views.restock.qty_error')} />
        )}
      </ScanContainer>
    </div>
  );
}

export default BatchDetails;
