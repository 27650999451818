import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BackButton, Modal, ScanContainer } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';
import barcodeImg from '@/assets/barcode_scanner.png';
import {
  fetchPickingProducts,
  fetchTripInfos
} from '@/redux/picking/picking.actions';
import validCheck from '@/assets/valid_check.png';
import missingProduct from '@/assets/missing_product.png';
import {
  resetPickingData,
  setCanScan,
  updatePickingStep,
  updateProductIndex
} from '@/redux/picking/picking.slice';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import PreviousProductBannerPicking from './PreviousProductBannerPicking/PreviousProductBannerPicking';
import CancelPreviousProductModalPicking from './CancelPreviousProductModalPicking/CancelPreviousProductModalPicking';

function Picking() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isScanValid, warning, message } = useSelector(
    (state) => state.barcode
  );
  const {
    pickingStep,
    productIndex,
    data,
    corValue,
    tripInfos,
    previousScanned,
    canScan
  } = useSelector((state) => state.picking);

  const { selectedRound } = useSelector((state) => state.round);
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    if (
      (data && pickingStep !== 'product_scanned_available') ||
      pickingStep === 'scan_product'
    )
      dispatch(updateProductIndex({ data }));
  }, [data, isScanValid]);

  useEffect(() => {
    if (!tripInfos) dispatch(fetchTripInfos(selectedRound.id));
  }, [tripInfos]);

  useEffect(() => {
    if (
      data &&
      data?.length &&
      !data.find((product) => product.pickingStatus === 'PENDING_SCAN')
    ) {
      if (
        tripInfos.packagesWithGroceryCompleted ===
        tripInfos.packagesWithGrocery - 1
      ) {
        dispatch(updatePickingStep({ pickingStep: 'cor_and_trip_done' }));
        setTimeout(() => {
          dispatch(resetIsScanValid());
        }, 2000);
      } else if (
        !(
          tripInfos.packagesWithGroceryCompleted ===
          tripInfos.packagesWithGrocery
        )
      ) {
        dispatch(updatePickingStep({ pickingStep: 'cor_done' }));
        setTimeout(() => {
          dispatch(resetPickingData());
          dispatch(resetIsScanValid());
        }, 2000);
      }
    }

    if (isScanValid && pickingStep === 'product_scanned_available') {
      dispatch(toggleModal(true));

      if (data.length !== productIndex + 1) {
        setTimeout(() => {
          dispatch(updateProductIndex({ data }));
          dispatch(updatePickingStep({ pickingStep: 'scan_product' }));
          dispatch(resetIsScanValid());
          dispatch(toggleModal(false));
          dispatch(setCanScan(true));
        }, 500);
      }
    }
    if (isScanValid && pickingStep === 'product_scanned_missing') {
      dispatch(toggleModal(true));

      if (data.length !== productIndex + 1) {
        setTimeout(() => {
          dispatch(updateProductIndex({ data }));
          dispatch(updatePickingStep({ pickingStep: 'scan_product' }));
          dispatch(resetIsScanValid());
          dispatch(toggleModal(false));
          dispatch(setCanScan(true));
        }, 2000);
      }
    }

    if (isScanValid === false && pickingStep === 'starting') {
      dispatch(updatePickingStep({ pickingStep: 'invalid_scan' }));
      dispatch(toggleModal(true));
      setTimeout(() => {
        dispatch(updatePickingStep({ pickingStep: 'starting' }));
        dispatch(resetIsScanValid());
        dispatch(resetPickingData());
        dispatch(toggleModal(false));
      }, 5000);
    } else if (isScanValid === false) {
      dispatch(updatePickingStep({ pickingStep: 'invalid_scan_product' }));
      dispatch(toggleModal(true));
      setTimeout(() => {
        dispatch(updatePickingStep({ pickingStep: 'scan_product' }));
        dispatch(resetIsScanValid());
        dispatch(toggleModal(false));
        dispatch(setCanScan(true));
      }, 2000);
    }
  }, [isScanValid, data]);

  useEffect(() => {
    if (
      (corValue && data === undefined) ||
      (corValue && pickingStep !== 'scan_product')
    ) {
      dispatch(
        fetchPickingProducts({ basket: corValue, tripId: selectedRound.id })
      );
    }
    if (
      pickingStep === 'cor_done' &&
      tripInfos?.packagesWithGroceryCompleted !== tripInfos?.packagesWithGrocery
    )
      dispatch(toggleModal(false));
  }, [pickingStep]);

  const modalContent = () => {
    if (pickingStep === 'starting') {
      return (
        <div className="picking-modal-container">
          <h1 className="picking-modal-container__header">
            {t('views.picking.starting')}
          </h1>
          <span className="picking-modal-container__instruction">
            {t('views.picking.starting_instructions')}
          </span>
          <div
            className="picking-modal-container__button"
            onClick={() => dispatch(toggleModal(false))}
          >
            OK
          </div>
        </div>
      );
    }

    if (pickingStep === 'cor_and_trip_done') {
      return (
        <div className="picking-modal-container">
          <h1>{t('views.picking.cor_and_trip_done_header')}</h1>
          <span className="picking-modal-container__instruction">
            {t('views.picking.cor_and_trip_done')}
          </span>
          <img src={validCheck} alt="valid check icon" />
        </div>
      );
    }

    if (pickingStep === 'product_scanned_available') {
      return (
        <div className="picking-modal-container">
          <span className="picking-modal-container__instruction">
            {t('views.picking.product_added')}
          </span>
          <img src={validCheck} alt="valid check icon" />
        </div>
      );
    }
    if (pickingStep === 'product_scanned_missing') {
      return (
        <div className="picking-modal-container">
          <span className="picking-modal-container__instruction">
            {t('views.picking.missing_product')}
          </span>
          <img src={missingProduct} alt="missing product icon" />
        </div>
      );
    }
    if (pickingStep === 'invalid_scan') {
      return (
        <div className="picking-modal-container">
          <span className="picking-modal-container__instruction">
            {t('views.picking.invalid_scan')} {t(message)}
          </span>
        </div>
      );
    }
    if (pickingStep === 'invalid_scan_product') {
      return (
        <div className="picking-modal-container">
          <span className="picking-modal-container__instruction">
            {t('views.picking.invalid_scan_product')}
          </span>
        </div>
      );
    }

    return null;
  };

  const instructions = () => {
    if (
      pickingStep === 'scan_product' ||
      pickingStep === 'invalid_scan_product' ||
      pickingStep === 'product_scanned_available'
    )
      return 'Scanner le produit';
    return 'Scanner une COR';
  };

  const boldInstructions = () => 'à remplir';

  useEffect(() => {
    if (
      pickingStep === 'starting' &&
      tripInfos?.packagesWithGroceryCompleted === 0
    )
      dispatch(toggleModal(true));

    if (pickingStep === 'cor_and_trip_done') {
      setTimeout(() => {
        dispatch(resetPickingData());
        dispatch(resetIsScanValid());
        dispatch(setCanScan(true));
        dispatch(toggleModal(false));
      }, 2000);
    }
  }, [pickingStep, tripInfos]);

  const missingProducts = data?.filter(
    (product) => product.pickingStatus === 'SCANNED_AS_MISSING'
  ).length;

  const productCount = () => {
    if (productIndex - missingProducts <= 0) {
      return 0;
    }
    return productIndex - missingProducts;
  };

  const layout = () => (
    <ScanContainer type={data === undefined ? 'picking' : 'product'}>
      <div className="picking-content-wrapper">
        {/* <div className="" onClick={() => {}}>
          dummy button
        </div> */}
        {previousScanned?.EAN13 !== undefined && (
          <div className="product-banner-picking">
            <PreviousProductBannerPicking
              product={previousScanned}
              warning={warning}
              setShouldDisplay={setShouldDisplay}
            />
            <CancelPreviousProductModalPicking
              product={previousScanned}
              warning={previousScanned.pickingStatus === 'SCANNED_AS_MISSING'}
              shouldDisplay={shouldDisplay}
              setShouldDisplay={setShouldDisplay}
            />
          </div>
        )}

        <div className="back-button-wrapper">
          <BackButton />
        </div>
        {pickingStep === 'starting' || pickingStep === 'invalid_scan' ? (
          <>
            <h1 className="step-title">{t('views.picking.fill_cor')}</h1>
            <img
              src={barcodeImg}
              className="barcode-scanner"
              alt="barcode scanner"
            />
          </>
        ) : (
          <div className="picking-products-tracker">
            <div className="picking-products-tracker__counter">
              {pickingStep === 'cor_done'
                ? data.length - missingProducts
                : productCount()}{' '}
              / {data?.length}
            </div>
            <div className="picking-products-tracker__counter__wrapper">
              <span>{t('views.picking.total_products')}</span>
              {missingProducts > 0 && (
                <div className="picking-products-tracker__missing-products">
                  {missingProducts} {t('views.picking.missing')}
                </div>
              )}
            </div>
          </div>
        )}
        {(pickingStep === 'scan_product' ||
          pickingStep === 'invalid_scan_product' ||
          pickingStep === 'product_scanned_available') &&
          pickingStep !== 'cor_done' &&
          data !== undefined && (
            <>
              <div className="picking-product-container">
                <div className="picking-product-container__header">
                  📍{data[productIndex]?.sourceName}
                </div>
                <img
                  className="picking-product-container__image"
                  src={data[productIndex]?.imageURL}
                  alt="product"
                />
                <span className="picking-product-container__name">
                  {data[productIndex]?.productName}
                </span>
                <div className="picking-product-container__barcode-info-container">
                  <img className="" src={barcodeImg} alt="barcode" />
                  {data[productIndex]?.EAN13}
                </div>
              </div>
              {data[productIndex + 1]?.sourceName !== undefined && (
                <div className="picking-next-product-info">
                  <span>{t('views.picking.next_product_location')}</span>
                  <div className="next-product-source">
                    📍{data[productIndex + 1]?.sourceName}
                  </div>
                </div>
              )}
            </>
          )}

        {pickingStep === 'cor_done' && (
          <div className="cor-done-container">
            <div className="cor-done-container__infos">
              <img src={validCheck} alt="valid check icon" />
              <span className="cor-done-container__infos__text">
                {corValue}
              </span>
            </div>
            <div className="cor-done-container__instructions">
              <span>{t('views.picking.clear_cor')}</span>
              <span>{t('views.picking.scan_new_cor')}</span>
            </div>
          </div>
        )}

        {pickingStep !== 'cor_done' && (
          <div
            className={`movement-instruction ${
              (pickingStep === 'scan_product' ||
                pickingStep === 'invalid_scan_product' ||
                pickingStep === 'product_scanned_available') &&
              'picking-scan-product'
            }`}
          >
            {instructions()} <br />
            {(pickingStep === 'starting' || pickingStep === 'invalid_scan') && (
              <div className="movement-instruction-bold">
                {boldInstructions()}
              </div>
            )}
          </div>
        )}

        <div className="picking-footer">
          <span className="picking-footer__pr-name">{selectedRound.name}</span>
          {pickingStep !== 'starting' &&
            pickingStep !== 'invalid_scan' &&
            pickingStep !== 'invalid_scan_product' && (
              <span>
                {t('views.picking.my_cor')}
                {corValue}
              </span>
            )}
          <div className="picking-footer__infos">
            <span>{t('views.picking.total_filled_cor')}</span>
            <div className="picking-footer__packages-count">
              {tripInfos?.packagesWithGroceryCompleted} /{' '}
              {tripInfos?.packagesWithGrocery}
            </div>
          </div>
        </div>

        <Modal>{modalContent()}</Modal>
      </div>
    </ScanContainer>
  );

  return layout();
}

export default Picking;
