import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, ScanContainer } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';

function ReturnBox() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    returnedBoxErrors,
    returnedBoxScans,
    availableBoxes,
    unavailableBoxes,
    isScanValid,
    returnBoxErrorMessage,
    value
  } = useSelector((state) => state.barcode);

  useEffect(() => {
    if (isScanValid === false) {
      dispatch(toggleModal(true));
      setTimeout(() => {
        dispatch(toggleModal(false));
        dispatch(resetIsScanValid());
      }, 2000);
    }
  }, [isScanValid, returnedBoxErrors]);

  // const missingProducts = [
  //   { alias: 'nameOfProduct1', name: 'Product 1' },
  //   { alias: 'nameOfProduct2', name: 'Product 2' },
  //   { alias: 'nameOfProduct3', name: 'Product 3' },
  //   { alias: 'nameOfProduct4', name: 'Product 4' },
  //   { alias: 'nameOfProduct5', name: 'Product 5' }
  // ];

  const modalContent = () => {
    if (isScanValid)
      return (
        <div className="returned-box-error-container">
          <div className="returned-box-error-container__text">
            {t('views.return_box.modal_headline')}
          </div>
          {returnedBoxErrors.map((error) => (
            <div className="returned-box-error-container__error" key={error}>
              <span className="returned-box-error-container__error__text">
                - {error}
              </span>
            </div>
          ))}
        </div>
      );

    if (returnBoxErrorMessage)
      return (
        <div className="returned-box-error-container">
          <h1>{t('views.return_box.api_error')}</h1>
          <div className="returned-box-error-container__text">
            {returnBoxErrorMessage}{' '}
          </div>
        </div>
      );

    return (
      <div className="returned-box-error-container">
        <div className="returned-box-error-container__text">
          {value}
          {t('views.return_box.bad_barcode')}
        </div>
      </div>
    );
  };

  return (
    <ScanContainer type="return_box">
      <div className="u-page-center">
        <div className="last-scanned-boxes-wrapper">
          <span>
            {t('views.return_box.available_boxes')} {availableBoxes}
          </span>
          <span>
            {t('views.return_box.unavailable_boxes')} {unavailableBoxes}
          </span>
          {returnedBoxScans.length > 0 && (
            <h1>{t('views.return_box.last_box_scanned')}</h1>
          )}
          {returnedBoxScans.map((returnedBox) => (
            <span>{returnedBox}</span>
          ))}
        </div>
        {returnedBoxErrors.length !== 0 && (
          <div
            className="returned-box-error-indicator"
            onClick={() => dispatch(toggleModal(true))}
          >
            <span className="returned-box-error-indicator__text">!</span>
          </div>
        )}
        <span className="u-scan">{t('views.return_box.scan_box')}</span>
      </div>
      <Modal>{modalContent()}</Modal>
    </ScanContainer>
  );
}

export default ReturnBox;
