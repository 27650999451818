import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SorterIcon from '@/assets/sorter_icon.png';
import { fetchTripsListMiniboard } from '@/redux/miniboard/miniboard.actions';
import { selectUser } from '@/redux/user/user.slice';

function Miniboard() {
  const { t } = useTranslation();
  const [sortedTripsList, setSortedTripsList] = useState(null);
  const [completionRate, setCompletionRate] = useState(null);
  const { data } = useSelector((state) => state.miniboard);
  const { hubId } = useSelector(selectUser);
  const [currentPriorityStatus, setCurrentPriorityStatus] = useState('ongoing');

  const dispatch = useDispatch();

  function getDynamicPriority(priorityStatus) {
    const statuses = ['ongoing', 'sent', 'todo'];
    const priority = {};
    let count = 1;

    priority[priorityStatus] = count;
    count += 1;

    statuses.forEach((status) => {
      if (status !== priorityStatus) {
        priority[status] = count;
        count += 1;
      }
    });

    return priority;
  }

  const sortArrayByStatus = (tripsList, priorityStatus) => {
    const statusPriority = getDynamicPriority(priorityStatus);

    return tripsList.sort(
      (a, b) => statusPriority[a.status] - statusPriority[b.status]
    );
  };

  const resolveSortMethod = () => {
    const statuses = ['ongoing', 'sent', 'todo'];
    const nextPriorityStatus =
      statuses[(statuses.indexOf(currentPriorityStatus) + 1) % statuses.length];
    setCurrentPriorityStatus(nextPriorityStatus);
    setSortedTripsList(
      sortArrayByStatus([...sortedTripsList], nextPriorityStatus)
    );
  };

  useEffect(() => {
    if (data === null) dispatch(fetchTripsListMiniboard(hubId));
    if (data && data.tripsData?.length > 0) {
      const todayDate = new Date().toJSON().slice(0, 10);
      const tripListSentFiltered = data.tripsData.filter(
        (trip) => trip.deliveryDay !== todayDate
      );
      setCompletionRate((data.shippedPackages / data.totalPackages) * 100);
      setSortedTripsList(
        sortArrayByStatus([...tripListSentFiltered], 'ongoing')
      );
    }
  }, [data]);

  return (
    <div className="miniboard-wrapper">
      <div className="miniboard-wrapper__banner">
        {t('views.miniboard.trip_progress')}
      </div>
      <div className="miniboard-wrapper__progress-bar-wrapper">
        <div className="miniboard-wrapper__progress-bar-wrapper__header">
          <span>{t('views.miniboard.total')}</span>
          <div>
            {data?.shippedPackages} / {data?.totalPackages}
          </div>
        </div>
        <div className="miniboard-wrapper__progress-bar-wrapper__bar">
          <div
            className="miniboard-wrapper__progress-bar-wrapper__bar__fill"
            style={{ width: `${completionRate}%` }}
          />
        </div>
      </div>
      <div className="miniboard-wrapper__header">
        <div className="miniboard-wrapper__header__infos-container">
          <span>{t('views.miniboard.trips')}</span>
          <span className="miniboard-wrapper__header__infos-container__state">
            {t('views.miniboard.box')}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex'
          }}
          onClick={() => {
            resolveSortMethod();
          }}
        >
          <img
            src={SorterIcon}
            alt="sorter icon"
            style={{ maxWidth: '14px' }}
          />
          <span style={{ marginRight: '2.5em' }}>
            {t('views.miniboard.status')}
          </span>
        </div>
      </div>
      {sortedTripsList?.map((trip) => (
        <div className="miniboard-wrapper__row" key={trip.tripName}>
          <div className="miniboard-wrapper__row__infos-container">
            <div className="miniboard-wrapper__row__infos-container__name">
              {trip.tripName}
            </div>
            <div className="miniboard-wrapper__row__infos-container__state">
              <div className="miniboard-wrapper__row__infos-container__state__boxes">
                {trip.shippedTripPackages} / {trip.totalTripPackages}
              </div>
            </div>
            <div className="miniboard-wrapper__row__infos-container__state__status">
              <div
                className={`miniboard-wrapper__row__infos-container__state__icon ${trip.status}`}
              />
              <span
                className={`miniboard-wrapper__row__infos-container__state__${trip.status}`}
              >
                {t(`views.miniboard.${trip.status}`)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Miniboard;
