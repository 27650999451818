export const LITTLE_HUB_KEY = 'little_hub';
export const BIG_HUB_KEY = 'big_hub';

export default [
  {
    id: 1,
    hub: 'Paris',
    hubKind: BIG_HUB_KEY,
    country: 'fr',
    fullname: 'Paris et région parisienne'
  },
  {
    id: 2,
    hub: 'Lyon',
    hubKind: BIG_HUB_KEY,
    country: 'fr',
    fullname: 'Lyon et région lyonnaise'
  },
  {
    id: 3,
    hub: 'Marseille',
    hubKind: LITTLE_HUB_KEY,
    country: 'fr',
    fullname: 'Marseille et sa région'
  },
  {
    id: 4,
    hub: 'Lille',
    hubKind: LITTLE_HUB_KEY,
    country: 'fr',
    fullname: 'Lille et sa région'
  },
  {
    id: 5,
    hub: 'Toulouse',
    hubKind: LITTLE_HUB_KEY,
    country: 'fr',
    fullname: 'Toulouse et sa région'
  },
  {
    id: 6,
    hub: 'Madrid',
    hubKind: LITTLE_HUB_KEY,
    country: 'es',
    fullname: 'Madrid y su region'
  },
  {
    id: 7,
    hub: 'Bordeaux',
    hubKind: LITTLE_HUB_KEY,
    country: 'fr',
    fullname: 'Bordeaux et sa région'
  }
];
