// Fundamentals
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const TRANSLATION_BUCKET_URL = process.env.REACT_APP_TRANSLATION_BUCKET_URL;

const FR_FR_KEY = 'fr-FR';
const ES_ES_KEY = 'es-ES';

export default function configureI18n() {
  // const defaultLocale = 'fr-FR';
  const lng = localStorage.getItem('lng') || FR_FR_KEY;

  const availableLanguages = [FR_FR_KEY, ES_ES_KEY];

  return (
    i18n
      // Load translation via HTTP, uses https://github.com/i18next/i18next-http-backends
      .use(HttpApi)
      // Detect language from user-agent, uses: https://github.com/i18next/i18next-browser-languageDetector
      // Uncomment this line when the ES translations are ready
      .use(initReactI18next)
      .init({
        keySeparator: false,
        lng,
        react: {
          useSuspense: false,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'span', 'p']
        },
        interpolation: {
          escapeValue: false
        },
        backend: {
          allowMultiLoading: false,
          loadPath: (language) => {
            const key = language.join('-');
            if (availableLanguages.includes(key)) {
              return [TRANSLATION_BUCKET_URL, `${key}.json`].join('/');
            }

            return null;
          }
        }
      })
  );
}
