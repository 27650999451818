import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { LITTLE_HUB_KEY } from '@/constants/hubs';

export const bookMobileRack = createAsyncThunk(
  'supplyProducts/bookMobileRack',
  async (place, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const littleMode = state.user.data.hubKind === LITTLE_HUB_KEY;
    const payload = {
      place,
      littleMode
    };

    if (littleMode) {
      payload.hubId = state.user.data.hubId;
    } else {
      payload.tripId = state.round.selectedRound.id;
    }

    try {
      await axios.post('/picking/place/book', payload);

      return null;
    } catch (e) {
      console.log({ e });
      return rejectWithValue(e.response.status);
    }
  }
);

export const fetchSupplyProducts = createAsyncThunk(
  'supplyProducts/fetchSupplyProducts',
  async (rack, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    try {
      const { mobileRack } = state.supplyProducts;
      const { hubId } = state.user.data;
      const rackData = rack || mobileRack;

      const { data } = await axios.get(
        // rackData = corData
        `picking/hub/${hubId}/place/${rackData}/shoppinglist`
      );

      dispatch(resetIsScanValid());

      return data;
    } catch (e) {
      return rejectWithValue(
        'Une erreur est survenue lors du getSupplyProducts'
      );
    }
  }
);

export const processProductScan = createAsyncThunk(
  'supplyProducts/processProductScan',
  async (warning, { dispatch, getState, rejectWithValue }) => {
    const state = getState().supplyProducts;
    const { hubId } = getState().user.data;

    const scannedProductIndex = state.data.findIndex(
      (p) => p.shopifyProductID === state.productToScan.shopifyProductID
    );
    const product = state.data[scannedProductIndex];
    if (!warning) {
      if (scannedProductIndex !== -1) {
        // correct product to scan

        try {
          const payload = {
            destinationName: state.productToScan.destinationNames[0],
            qty: 1,
            EAN13: product.EAN13,
            add: true
          };
          await axios.put(
            `picking/hub/${hubId}/allocatedplace/update/content`,
            payload
          );
          // we return the product to update previous product scan with the scanned product
          return product;
        } catch (e) {
          return rejectWithValue(
            'Une erreur est survenue lors de lupdate de la quantité'
          );
        }
      } else {
        console.log(scannedProductIndex);
        return null;
      }
    } else {
      // TODO: Produit manquant
      const payload = [];
      console.log('scan produit manquant in processProductScan');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < product.destinationNames.length; i++) {
        payload.push({
          destinationName: product.destinationNames[i],
          qty: 1,
          EAN13: product.EAN13,
          shopifyID: product.shopifyProductID,
          add: true
        });
      }
      try {
        const { data } = await axios.put(
          `picking/hub/${hubId}/allocatedplace/update/missing`,
          payload
        );
        return product;
      } catch (e) {
        return rejectWithValue(
          'Une erreur est survenue lors de lupdate missing qty'
        );
      }
    }
  }
);

export const cancelPreviousAction = createAsyncThunk(
  'supplyProducts/cancelPreviousAction',
  async (warning, { dispatch, getState, rejectWithValue }) => {
    const state = getState().supplyProducts;
    const { hubId } = getState().user.data;
    const previousScannedIndex = state.data.findIndex(
      (p) => p.shopifyProductID === state.previousScanned.shopifyProductID
    );
    const product = state.previousScanned;
    if (!warning) {
      // correct product to scan

      try {
        const payload = {
          destinationName: state.previousScanned.destinationNames[0],
          qty: 1,
          EAN13: product.EAN13,
          add: false
        };
        await axios.put(
          `picking/hub/${hubId}/allocatedplace/update/content`,
          payload
        );

        // TODO: change the logic of refetching supplyProducts to be similar in  processProductScan thunk
        await dispatch(fetchSupplyProducts());

        // we return the product to update previous product scan with the scanned product
        return product;
      } catch (e) {
        return rejectWithValue(
          'Une erreur est survenue lors de lupdate de la quantité'
        );
      }
    } else {
      // TODO: Produit manquant
      const payload = [];
      console.log('scan produit manquant in processProductScan');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < state.data[previousScannedIndex].missingQty; i++) {
        payload.push({
          destinationName: product.destinationNames[i],
          qty: 1,
          EAN13: product.EAN13,
          shopifyID: product.shopifyProductID,
          add: false
        });
      }
      try {
        await axios.put(
          `picking/hub/${hubId}/allocatedplace/update/missing`,
          payload
        );

        await dispatch(fetchSupplyProducts());
        return product;
      } catch (e) {
        return rejectWithValue(
          'Une erreur est survenue lors de lupdate missing qty'
        );
      }
    }
  }
);
