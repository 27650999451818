import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';
import { cancelPreviousAction } from '@/redux/picking/picking.actions';

const CancelPreviousProductModalPicking = ({
  product,
  warning,
  shouldDisplay,
  setShouldDisplay
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { productName } = product;

  const handleConfirmClick = () => {
    dispatch(cancelPreviousAction(warning));
    dispatch(toggleModal(false));
    setShouldDisplay(false);
  };

  const handleCancelClick = () => {
    dispatch(toggleModal(false));
    setShouldDisplay(false);
  };
  return shouldDisplay ? (
    <Modal>
      <div className="v-cancel-prev-modal">
        <div className="v-cancel-prev-modal__header">
          {!warning ? (
            <>
              {t('views.cancel_previous_product_modal.header_1')} <br />
              <span className="v-cancel-prev-modal__product">
                1 x {productName}
              </span>{' '}
              <br />
              {t('views.cancel_previous_product_modal.header_2')} <br />
            </>
          ) : (
            <>
              {t('views.cancel_previous_product_modal.cancel_1')} <br />
              <span className="v-cancel-prev-modal__product">
                {t('views.cancel_previous_product_modal.cancel_2')} (
                {productName})
              </span>
            </>
          )}
        </div>
        <div className="v-cancel-prev-modal__buttons">
          <Button
            className="v-cancel-prev-modal__cancel-btn"
            title={t('views.cancel_previous_product_modal.no')}
            onClick={handleCancelClick}
            isPrimary
          />
          <Button
            className="v-cancel-prev-modal__confirm-btn"
            title={t('views.cancel_previous_product_modal.yes')}
            onClick={handleConfirmClick}
            isPrimary
          />
        </div>
      </div>
    </Modal>
  ) : null;
};

export default CancelPreviousProductModalPicking;
