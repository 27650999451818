/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BackButton, FlashScreen, Loader } from '@/components';
import { axios } from '@/helpers/api';
import { selectIsLittleHub, selectUser } from '@/redux/user/user.slice';

const ResetRack = () => {
  const { t } = useTranslation();

  const [racks, setRacks] = useState([]);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState('idle');
  const { hubId } = useSelector(selectUser);
  const [emptyRackError, setEmptyRackError] = useState(false);
  const isLittleHub = useSelector(selectIsLittleHub);

  const getRacks = async () => {
    setStatus('loading');
    try {
      const { data } = await axios.get(`picking/hub/${hubId}/places/status`);
      setStatus('succeeded');
      setRacks(data.filter((rack) => rack.trip));
    } catch (e) {
      setError(e);
      setStatus('failed');
      setRacks([]);
    }
  };

  const handleResetRack = async (place) => {
    const littleHub = {
      hubId,
      place
    };

    const bigHub = {
      hubId,
      mob: place
    };

    try {
      if (isLittleHub) await axios.put('picking/place/unbook', littleHub);
      else await axios.put('picking/place/empty', bigHub);
    } catch (e) {
      setEmptyRackError(true);
      setTimeout(() => {
        setEmptyRackError(false);
      }, 3000);
      return;
    }
    getRacks();
  };

  useEffect(() => {
    getRacks();
  }, []);

  const renderedRacks = racks?.map((rack) => (
    <>
      <div>
        <div className="p-reset-rack__rack">
          <div className="p-reset-rack__rack-name">{rack.placeName}</div>
          <button
            className="p-reset-rack__btn"
            type="button"
            onClick={() => handleResetRack(rack.placeName)}
          >
            {isLittleHub
              ? t('views.reset_rack.reset_button')
              : t('views.reset_rack.empty_button')}
          </button>
        </div>
        {!isLittleHub && <div>{rack.trip}</div>}
      </div>
      <hr />
    </>
  ));
  return (
    <div className="p-reset-rack">
      <BackButton />
      <div className="p-reset-rack__container">
        {status === 'loading' ? (
          <Loader />
        ) : racks.length ? (
          <>
            <div className="p-reset-rack__title">
              {t('views.reset_rack.title')}
            </div>
            <div className="p-reset-rack__racks">{renderedRacks}</div>
          </>
        ) : (
          <p className="p-reset-rack__not-found">
            {t('views.reset_rack.not_found')} ❌
          </p>
        )}
      </div>
      {emptyRackError && (
        <FlashScreen
          type="error"
          message={t('views.reset_rack.cannot_empty_mob')}
        />
      )}
    </div>
  );
};

export default ResetRack;
