/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader, SelectOption } from '@/components';
import { setRound } from '@/redux/round/round.slice';
import { fetchTripsList } from '@/redux/round/round.actions';
import { selectIsLittleHub } from '@/redux/user/user.slice';

const SelectRound = () => {
  const { t } = useTranslation();

  const { data, status } = useSelector((state) => state.round);
  const isLittleHub = useSelector(selectIsLittleHub);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleModeSelection = (round) => {
    dispatch(setRound(round));
  };

  async function fetchTrips() {
    try {
      dispatch(fetchTripsList('picking')).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (isLittleHub) {
      navigate('/supply/no-trips');
    } else {
      fetchTrips();
    }
  }, [dispatch]);

  return (
    <div className="v-select-mode u-page-center">
      <div className="v-select-mode__title">
        {t('views.select_round.title')}
      </div>
      {status === 'loading' ? (
        <Loader />
      ) : data.length ? (
        <div
          className={`c-select-round__content${
            data.length === 1 ? ' c-select-round__content--one-col' : ''
          }`}
        >
          {data.map((option) => {
            const updatedOption = { ...option };
            if (updatedOption.name.toLowerCase().includes('lad')) {
              updatedOption.name = `📦 ${updatedOption.name}`;
            } else {
              updatedOption.name = `🏪 ${updatedOption.name}`;
            }
            const isSupplyCompleted =
              option.totalPackages === option.provisionedPackages;

            return (
              <SelectOption
                isDisabled={isSupplyCompleted}
                option={option}
                link={option.name}
                name={`${updatedOption.name} ${option.provisionedPackages}/${
                  option.totalPackages
                } ${isSupplyCompleted ? '✅' : ''}`}
                onSelect={handleModeSelection}
                line={option.lineId}
              />
            );
          })}
        </div>
      ) : (
        <p className="v-select-mode__not-found">
          {t('views.select_round.not_found')} ❌
        </p>
      )}
    </div>
  );
};

export default SelectRound;
