import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { axios } from '@/helpers/api';

const sortRounds = (rounds) =>
  rounds?.sort((roundA, roundB) => {
    const dayAIndex = moment(roundA.day).day();
    const dayBIndex = moment(roundB.day).day();
    if (dayAIndex === dayBIndex) {
      return roundA.name.toLowerCase().includes('LAD') ? 1 : -1;
    }
    return dayAIndex - dayBIndex;
  });

// to do : fetchtripstate

export const fetchTripsList = createAsyncThunk(
  'round/fetchTripsList',
  async (status, { rejectWithValue, getState }) => {
    const { hubId } = getState().user.data;
    // date is last sunday
    const date = moment().startOf('week').format('YYYY-MM-DD');

    try {
      const { data } = await axios.get(
        `picking/trips/hub/${hubId}/${date}/list${
          status ? `?status=${status}` : ''
        }`
      );

      return sortRounds(data);
    } catch (e) {
      return rejectWithValue('Erreur fetchTripsList');
    }
  }
);

export const switchTripStatus = createAsyncThunk(
  'round/switchTripStatus',
  async (payload, { rejectWithValue }) => {
    try {
      await axios.put(`picking/trips/update/status`, payload);
      return null;
    } catch (e) {
      return rejectWithValue('Erreur switchTripStatus');
    }
  }
);
