import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';

// eslint-disable-next-line import/prefer-default-export
export const inspectMob = createAsyncThunk(
  'inspectMob/inspectMob',
  async (value, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    try {
      const { data } = await axios.get(
        `/picking/hub/${state.user.data.hubId}/mobs`
      );

      return data;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.response.status);
    }
  }
);
