import { createSlice } from '@reduxjs/toolkit';
import { inspectMob } from './inspectMob.actions';

const initialState = {
  data: null,
  selectedMob: null
};

const inspectMobSlice = createSlice({
  name: 'inspectMob',
  initialState,
  reducers: {
    resetInspectMob: (state) => {
      state.data = null;
      state.selectedMob = null;
    },
    setMob: (state, action) => {
      state.selectedMob = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(inspectMob.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  }
});

export const { resetInspectMob, setMob } = inspectMobSlice.actions;

export default inspectMobSlice.reducer;
