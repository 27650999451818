import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';

const PreviousProductBannerPicking = ({
  product,
  warning,
  setShouldDisplay
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { productName, pickingStatus } = product;

  return (
    <div className="v-previous-product-picking">
      <div className="v-previous-product-picking__header">
        {pickingStatus === 'SCANNED_AS_PRESENT' ? (
          <>
            {t('views.previous_product_banner.header_1')}:{' '}
            <span className="v-previous-product-picking__title">
              {productName}
            </span>{' '}
          </>
        ) : (
          <>
            {t('views.previous_product_banner.header_1')}:{' '}
            <span className="v-previous-product-picking__title">
              {t(
                'views.previous_product_banner.header_2_missing_product_picking'
              )}{' '}
              ({productName})
            </span>
          </>
        )}
      </div>
      <div className="v-previous-product-picking__cancel-btn">
        <Button
          isPrimary
          title={
            // !warning
            // ? `Annuler dernier ajout de ${title}`
            // :
            t('views.previous_product_banner.cancel')
          }
          onClick={() => {
            dispatch(toggleModal(true));
            setShouldDisplay(true);
          }}
        />
      </div>
    </div>
  );
};

export default PreviousProductBannerPicking;
