import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { LITTLE_HUB_KEY } from '@/constants/hubs';

export const bookMobileRack = createAsyncThunk(
  'supplyProducts/bookMobileRack',
  async (place, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const littleMode = state.user.data.hubKind === LITTLE_HUB_KEY;
    const payload = {
      place,
      littleMode
    };

    if (littleMode) {
      payload.hubId = state.user.data.hubId;
    } else {
      payload.tripId = state.round.selectedRound.id;
    }

    try {
      await axios.post('/picking/place/book', payload);

      return null;
    } catch (e) {
      console.log({ e });
      return rejectWithValue(e.response.status);
    }
  }
);

export const fetchPickingProducts = createAsyncThunk(
  'supplyProducts/fetchPickingProducts',
  async ({ basket, tripId }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    try {
      const { hubId } = state.user.data;
      const { data } = await axios.get(
        `picking/hub/${hubId}/trip/${tripId}/place/${basket}/groceries`
      );

      return data;
    } catch (e) {
      console.log({ e });
      return rejectWithValue({
        status: e?.response?.status,
        message: e?.response?.data
      });
    }
  }
);

export const processProductScan = createAsyncThunk(
  'supplyProducts/processProductScan',
  async (warning, { dispatch, getState, rejectWithValue }) => {
    const state = getState().picking;
    const { hubId } = getState().user.data;

    const scannedProductIndex = state.productIndex;
    const product = state.data[scannedProductIndex];

    if (!warning) {
      if (product.EAN13 === state.productToScan.EAN13) {
        // correct product to scan

        try {
          const payload = {
            destinationName: state.corValue,
            qty: 1,
            EAN13: product.EAN13,
            add: true
          };
          const res = await axios.put(
            `picking/hub/${hubId}/allocatedplace/update/content`,
            payload
          );
          // we return the product to update previous product scan with the scanned product
          return product;
        } catch (e) {
          return rejectWithValue(
            'Une erreur est survenue lors de lupdate de la quantité'
          );
        }
      } else {
        console.log(scannedProductIndex);
        return null;
      }
    } else {
      // TODO: Produit manquant
      const payload = [];
      // eslint-disable-next-line no-plusplus
      payload.push({
        destinationName: state.corValue,
        qty: 1,
        EAN13: product.EAN13,
        shopifyID: product.shopifyProductID,
        add: true
      });
      try {
        const { data } = await axios.put(
          `picking/hub/${hubId}/allocatedplace/update/missing`,
          payload
        );
        return product;
      } catch (e) {
        return rejectWithValue(
          'Une erreur est survenue lors de lupdate missing qty'
        );
      }
    }
  }
);

export const fetchTripInfos = createAsyncThunk(
  'supplyProducts/fetchTripInfos',
  async (tripId, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    try {
      const { hubId } = state.user.data;
      const { data } = await axios.get(
        `picking/hub/${hubId}/trip/${tripId}/groceryCompletion`
      );

      return data;
    } catch (e) {
      console.log({ e });
      return rejectWithValue(e.response.status);
    }
  }
);

export const cancelPreviousAction = createAsyncThunk(
  'supplyProducts/cancelPreviousAction',
  async (warning, { dispatch, getState, rejectWithValue }) => {
    const state = getState().picking;
    const { selectedRound } = getState().round;
    const { hubId } = getState().user.data;
    const previousScannedIndex = state.data.findIndex(
      (p) => p.shopifyProductID === state.previousScanned.shopifyProductID
    );
    const product = state.previousScanned;

    console.log({ product, selectedRound });

    if (!warning) {
      // correct product to scan

      try {
        const payload = {
          destinationName: state.corValue,
          qty: 1,
          EAN13: product.EAN13,
          add: false
        };
        await axios.put(
          `picking/hub/${hubId}/allocatedplace/update/content`,
          payload
        );

        // TODO: change the logic of refetching supplyProducts to be similar in  processProductScan thunk
        await dispatch(
          fetchPickingProducts({
            basket: state.corValue,
            tripId: selectedRound.id
          })
        );

        // we return the product to update previous product scan with the scanned product
        return product;
      } catch (e) {
        return rejectWithValue(
          'Une erreur est survenue lors de lupdate de la quantité'
        );
      }
    } else {
      // TODO: Produit manquant
      const payload = [];
      console.log('scan produit manquant in processProductScan');
      // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < state.data[previousScannedIndex].missingQty; i++) {
      payload.push({
        destinationName: state.corValue,
        qty: 1,
        EAN13: product.EAN13,
        shopifyID: product.shopifyProductID,
        add: false
      });
      // }

      console.log('in missing action:', { payload });
      try {
        await axios.put(
          `picking/hub/${hubId}/allocatedplace/update/missing`,
          payload
        );

        await dispatch(
          fetchPickingProducts({
            basket: state.corValue,
            tripId: selectedRound.id
          })
        );
        return product;
      } catch (e) {
        return rejectWithValue(
          'Une erreur est survenue lors de lupdate missing qty'
        );
      }
    }
  }
);
