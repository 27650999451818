/* eslint-disable no-return-assign */
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '@/redux/modal/modal.slice';

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
const Modal = ({ children, onOpen, onClose }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modal);

  const closeModal = () => {
    dispatch(toggleModal(false));
    if (onClose) {
      onClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
