import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';

export const fetchTripsListMiniboard = createAsyncThunk(
  'miniboard/fetchTripsListMiniboard',
  async (hubId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/warehouses/${hubId}/trips/completion`);

      return data;
    } catch (e) {
      return rejectWithValue('Error fetch trips for miniboard');
    }
  }
);

export default fetchTripsListMiniboard;
