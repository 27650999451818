/* eslint-disable no-nested-ternary */
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { setMode } from '@/redux/mode/mode.slice';
import DateAndTime from './DateAndTime';
import HUBS from '@/constants/hubs';
import { selectIsLittleHub, selectUser } from '@/redux/user/user.slice';
import { isProd } from '@/helpers/api';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { resetRestock } from '@/redux/restock/restock.slice';
import { resetLocalStorage } from '@/helpers/helpers';
import { closeSession, getBatchs } from '@/redux/restock/restock.actions';
import { resetWarehouseFlows } from '@/redux/warehouseFlows/warehouseFlows.slice';
import { resetPickingData } from '@/redux/picking/picking.slice';
import { resetFillv2ProductsData } from '@/redux/fillv2/fillv2.slice';
import { resetFillProductsData } from '@/redux/fillProducts/fillProducts.slice';
import { APP_VERSION } from '@/views/SignIn/SignInPage/SignInPage';
import { resetLabelsFc } from '@/redux/printLabelsFc/printLabelsFc.slice';
import { resetInspectMob } from '@/redux/inspectMob/inspectMob.slice';
import { resetMiniboard } from '@/redux/miniboard/miniboard.slice';

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, hubId, isAdmin, fake } = useSelector(selectUser);
  const { id: userID } = useSelector(selectUser);

  const { selectedMode, data } = useSelector((state) => state.mode);
  const userHub = HUBS.find((hub) => hub.id === hubId) ?? {};

  const { batchs } = useSelector((state) => state.restock);

  const [batchToPause, setBatchToPause] = useState(null);

  const getInTreatmentBatch = () => {
    const batch = batchs?.data?.find((b) => b.in_treatment);
    return batch?.id;
  };

  const handleChangeMode = () => {
    if (selectedMode.name === 'restock') {
      if (batchToPause)
        dispatch(closeSession({ batchId: batchToPause, employeeId: userID }));
    }
    dispatch(resetWarehouseFlows());
    dispatch(resetIsScanValid());
    dispatch(resetRestock());
    dispatch(resetPickingData());
    dispatch(resetFillv2ProductsData());
    dispatch(resetFillProductsData());
    dispatch(resetLabelsFc());
    dispatch(resetInspectMob());
    dispatch(resetMiniboard());
    resetLocalStorage(userID);
  };

  const logout = () => {
    if (batchToPause)
      dispatch(closeSession({ batchId: batchToPause, employeeId: userID }));
    localStorage.removeItem('user');
    localStorage.removeItem('lng');
    dispatch(resetRestock());
    resetLocalStorage(userID);
    navigate('/signin');
    window.location.reload();
  };

  const handleGoToAdminPage = () => {
    dispatch(setMode(data.find((m) => m.id === 'admin')));
  };

  useEffect(() => {
    if (userID) dispatch(getBatchs(userID));
  }, [userID]);

  useEffect(() => {
    setBatchToPause(getInTreatmentBatch());
  }, [batchs]);

  return (
    <div className="header">
      <div className="header__general-info">
        <span className="header__user-name">
          {isAdmin ? (
            <Link to="admin" onClick={handleGoToAdminPage}>
              {name}.
            </Link>
          ) : (
            { name }
          )}
        </span>

        <span className="header__date">
          <span className="header__hub">
            {userHub.hub} - <DateAndTime /> - {APP_VERSION}
          </span>
        </span>

        <button className="header__admin-icon" type="button" onClick={logout}>
          <LogoutIcon />
        </button>
      </div>

      {!!selectedMode.name && (
        <span className="header__selected-mode">
          Mode : {t(`views.select_mode.${selectedMode.name}`)}{' '}
          <Link to="/" onClick={handleChangeMode}>
            <span className="header__change-mode">
              ({t('component.header.change_mode')})
            </span>
          </Link>
        </span>
      )}
    </div>
  );
}
