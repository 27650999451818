import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ScanContainer, FlashScreen } from '@/components';
import barcodeImg from '@/assets/barcode_scanner.png';
import validCheck from '@/assets/valid_check.png';

import { setMode } from '@/redux/mode/mode.slice';

const FillScanBasket = () => {
  const { t } = useTranslation();

  const { isScanValid, warning } = useSelector((state) => state.barcode);
  const { data: modes, selectedMode } = useSelector((state) => state.mode);

  const { data } = useSelector((state) => state.fillv2);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedMode.id !== 'fillv2') {
      dispatch(setMode(modes.find((m) => m.id === 'fillv2')));
    }
  }, []);

  let view = null;
  const qtyToScan = data.products?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );

  useEffect(() => {}, [data]);

  const alreadyScanned = [];
  const productsInCOR = [];

  const missingQty = data.products?.reduce(
    (prev, curr) => prev + curr.missingQuantity,
    0
  );

  data.products?.map((p) =>
    p.alreadyPicked ? alreadyScanned.push(p) : productsInCOR.push(p)
  );

  view =
    qtyToScan === undefined ? (
      <div className="u-page-center">
        <br />
        <img
          src={barcodeImg}
          alt="barcode"
          style={{ position: 'absolute', top: '8em' }}
        />
        <span className="u-scan" style={{ top: '26em', position: 'absolute' }}>
          {t('views.scan_basket.scan')}
        </span>
      </div>
    ) : (
      <>
        <div className="v-fillv2-scan">
          <div className="v-fillv2-scan__placev2">
            <div className="v-fillv2-scan__placev2__header">
              {t('views.fillv2.basket_already_validated')}
              <img src={validCheck} alt="valid check icon" />
            </div>
          </div>
          <br />

          <div className="v-fillv2-scan__total-products-info">
            <div className="v-fillv2-scan__total-products-info__infos-wrapper">
              <div className="v-fillv2-scan__total-products-info__infos-wrapper__counter">
                {data?.products?.reduce(
                  (sum, product) => sum + product.packagedQuantity,
                  0
                )}
              </div>
              <span className="v-fillv2-scan__total-products-info__infos-wrapper__text">
                {t('views.fillv2.total_products')}{' '}
              </span>
            </div>
          </div>
          <ul>
            {alreadyScanned.map((p) => (
              <li className=" v-fillv2-scan__scanned">
                {p.packagedQuantity} x {p.name}
              </li>
            ))}{' '}
          </ul>
          <br />
        </div>

        <div className="u-scan">
          <div className="fill-scan-instructions-wrapper">
            {t('views.fillv2.scan_another_basket')}
          </div>
        </div>
      </>
    );

  if (isScanValid === undefined) {
    console.log('1');
  } else if (isScanValid) {
    // if placeName null and products array is empty => no grocery for this basket

    if (!data.placeName && data?.products?.length === 0) {
      console.log('2');
      return (
        <div className="u-page-center">
          <span style={{ textAlign: 'center' }}>
            {t('views.scan_basket.no_product')} <strong>{data.package}</strong>{' '}
            <br />
            {t('views.scan_basket.next_basket')} ✅ <br /> <br />
          </span>
          <ScanContainer type="basket">
            <span className="u-scan">{t('views.scan_basket.scan')}</span>
          </ScanContainer>
        </div>
      );
    }

    if (warning) {
      console.log('3');
      return <FlashScreen type="error" message={warning} />;
    }

    if (data.placeName && qtyToScan !== 0) {
      console.log('4');
      return <Navigate replace to={`${data.placeName}`} />;
    }
  } else {
    console.log('5');
    view = <FlashScreen type="error" message={warning} />;
  }

  console.log('6');
  return <ScanContainer type="basket">{view}</ScanContainer>;
};

export default FillScanBasket;
