import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateScan } from '@/redux/barcode/barcode.actions';

const ScanContainer = ({ type, children, expectedValue }) => {
  const dispatch = useDispatch();
  const { restockStep } = useSelector((state) => state.restock);
  const { canScan } = useSelector((state) => state.picking);

  useEffect(() => {
    if (canScan !== false) {
      const eventListener = (e) => {
        dispatch(
          validateScan({
            type,
            value: e.detail.data,
            expectedValue,
            restockStep
          })
        );
      };
      // onScan event is triggered from the device scanner upon a scan, and is caught here
      document.addEventListener('onScan', eventListener);

      return () => {
        document.removeEventListener('onScan', eventListener);
      };
    }
    return undefined;
  }, [canScan, expectedValue, restockStep, type]); // Add canScan as a dependency

  return children;
};

export default ScanContainer;
