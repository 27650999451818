import { createSlice } from '@reduxjs/toolkit';
import { fetchTripsListMiniboard } from './miniboard.actions';

const initialState = {
  data: null
};

const miniboardSlice = createSlice({
  name: 'miniboard',
  initialState,
  reducers: {
    resetMiniboard: (state) => {
      state.data = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchTripsListMiniboard.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  }
});

export const { resetMiniboard } = miniboardSlice.actions;

export default miniboardSlice.reducer;
