import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScanContainer, FlashScreen, Modal } from '@/components';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { isEmpty } from '@/helpers/helpers';
import { toggleModal } from '@/redux/modal/modal.slice';
import validCheck from '@/assets/valid_check.png';
import { resetFillv2ProductsData } from '@/redux/fillv2/fillv2.slice';

const Fillv2ScanProduct = ({ basket }) => {
  const { t } = useTranslation();

  const { isScanValid, warning, type } = useSelector((state) => state.barcode);
  const { data, fillv2Step } = useSelector((state) => state.fillv2);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalContent = () => (
    <div className="picking-modal-container">
      <span className="picking-modal-container__instruction">
        {t('views.fillv2.basket_validated')}
      </span>
      <img src={validCheck} alt="valid check icon" />
    </div>
  );

  useEffect(() => {
    if (isEmpty(data)) {
      navigate('/fillv2');
    }
  }, [data]);

  useEffect(() => {
    if (fillv2Step === 'basket_validated') {
      dispatch(toggleModal(true));

      setTimeout(() => {
        dispatch(toggleModal(false));
        dispatch(resetIsScanValid());
        dispatch(resetFillv2ProductsData());
        navigate('/fillv2');
      }, [2000]);
    }
  }, [fillv2Step]);

  useEffect(() => {
    if (isScanValid && type === 'fillv2') {
      dispatch(resetIsScanValid());
    }
  }, [isScanValid, type]);

  const qtyToScan = data.products?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );

  if (qtyToScan === 0) {
    return <Navigate replace to="/fillv2" />;
  }

  const alreadyScanned = [];
  const productsInCOR = [];

  data.products?.map((p) =>
    p.alreadyPicked ? alreadyScanned.push(p) : productsInCOR.push(p)
  );
  const missingQty = data.products?.reduce(
    (prev, curr) => prev + curr.missingQuantity,
    0
  );

  const view = (
    <>
      <div className="v-fillv2-scan">
        <Modal>{modalContent()}</Modal>
        <div className="v-fillv2-scan__placev2">
          <div className="v-fillv2-scan__placev2__header">
            {t('views.fillv2.order_in')}
          </div>
          <span className="v-fillv2-scan__place-number">{data.placeName}</span>
        </div>
        <br />

        <div className="v-fillv2-scan__total-products-info">
          <div className="v-fillv2-scan__total-products-info__infos-wrapper">
            <div className="v-fillv2-scan__total-products-info__infos-wrapper__counter">
              {data?.products?.reduce(
                (sum, product) => sum + product.quantity,
                0
              )}
            </div>
            <span className="v-fillv2-scan__total-products-info__infos-wrapper__text">
              {t('views.fillv2.total_products')}
            </span>
          </div>
        </div>
        <ul>
          {productsInCOR.map((p) => (
            <li className="v-fillv2-scan__to-scan">
              {p.quantity} x {p.name}
            </li>
          ))}{' '}
        </ul>
        <br />
        {missingQty > 0 && (
          <div className="v-fillv2-scan__note">
            {t('views.fill_scan_product.scan_note')} 📄.{' '}
          </div>
        )}
      </div>

      <div className="u-scan">
        <div className="fill-scan-instructions-wrapper">
          {t('views.fillv2.scan')}{' '}
          <div className="fill-scan-instructions-wrapper__cor-id">
            {' '}
            {data.placeName}
          </div>
        </div>
      </div>
    </>
  );

  if (warning) {
    return <FlashScreen type="error" message={warning} />;
  }

  // if placeName null and products array is empty => no grocery for this basket

  return (
    <ScanContainer type="fillv2" expectedValue={data.placeName}>
      {view}
    </ScanContainer>
  );
};

export default Fillv2ScanProduct;
