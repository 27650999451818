export const sortProductsByLocation = (products) => {
  if (products && Array.isArray(products)) {
    return products.sort((p_far, p_near) => {
      if (p_far.location > p_near.location) return 1;
      if (p_far.location < p_near.location) return -1;
      return 0;
    });
  }
  return null;
};

export const isEmpty = (obj) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const resetLocalStorage = (userID) => {
  localStorage.removeItem(`batchState_${userID}`);
  localStorage.removeItem(`productIndex_${userID}`);
  localStorage.removeItem(`restockStep_${userID}`);
  localStorage.removeItem(`warehouseFlowStep_${userID}`);
  localStorage.removeItem(`productsMag_${userID}`);
  localStorage.removeItem(`productsStored_${userID}`);
};

export const translateMobIntoCor = (mob) => {
  let corNumber = 0;

  // Check if the format is Type A (MOB-XXX-XX-XX)
  if (mob.startsWith('MOB-') && !Number.isNaN(parseInt(mob.charAt(4)))) {
    const parts = mob.split('-');
    const mainPart = parseInt(parts[1]); // MOB-XXX
    const subPart1 = parseInt(parts[2]); // XX
    const subPart2 = parseInt(parts[3]); // XX

    corNumber = (mainPart - 1) * 9 + (subPart1 - 1) * 3 + subPart2;
  }
  // Check if the format is Type B (MOB-X-X-X)
  else if (mob.startsWith('MOB-') && Number.isNaN(parseInt(mob.charAt(4)))) {
    const parts = mob.split('-');
    const mainPart = parts[1].charCodeAt(0) - 'A'.charCodeAt(0) + 1; // MOB-X
    const subPart1 = parseInt(parts[2]); // X
    const subPart2 = parseInt(parts[3]); // X

    corNumber = (mainPart - 1) * 9 + (subPart1 - 1) * 3 + subPart2;
  }

  // Format corNumber to be 'XXX' format
  return `COR-${corNumber.toString().padStart(3, '0')}`;
};
