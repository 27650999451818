import { createAsyncThunk } from '@reduxjs/toolkit';
import HUBS from '@/constants/hubs';

import { axios } from '@/helpers/api';

// eslint-disable-next-line import/prefer-default-export
export const getProductsList = createAsyncThunk(
  'missingProducts/getProductsList',
  async (hubId, { dispatch, getState, rejectWithValue }) => {
    const hubFullName = HUBS.find((h) => h.id === hubId).fullname;

    try {
      const { data } = await axios.get(
        `picking/ponfigue/warehouses/${hubFullName}`
      );

      return data;
    } catch (e) {
      console.log({ e });
      return rejectWithValue(e.response.status);
    }
  }
);

export const updateStationProducts = createAsyncThunk(
  'missingProducts/updateStationProducts',
  async (
    { hubId, lineId, stationId, slotId, payload },
    { dispatch, getState, rejectWithValue }
  ) => {
    const hubFullName = HUBS.find((h) => h.id === hubId).fullname;

    try {
      const { data } = await axios.patch(
        `/picking/ponfigue/warehouses/${hubFullName}/lines/${lineId}/stations/${stationId}/slots/${slotId}`,
        payload
      );

      return data;
    } catch (e) {
      console.log({ e });
      return rejectWithValue(e.response.status);
    }
  }
);
