import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';
import hubs from '@/constants/hubs';

// eslint-disable-next-line import/prefer-default-export
export const shipBox = createAsyncThunk(
  'shipBox/shipBox',
  async (value, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    const hubName = hubs.find(
      (hub) => hub.id === state.user.data.hubId
    ).fullname;

    // get dynamic hub kind when extending usage
    const hubKind = 'little';

    const payload = {
      warehouseId: hubName,
      lineId: 'A',
      boxId: parseInt(value)
    };

    try {
      const { data } = await axios.post(
        `/warehouses/${hubName}/shipBox?hubCategory=${hubKind}`,
        payload
      );

      return data;
    } catch (error) {
      console.error(error.message, error.response);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data
      });
    }
  }
);
