import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function MobInfos() {
  const { t } = useTranslation();
  const { selectedMob } = useSelector((state) => state.inspectMob);

  return (
    <div className="mob-infos-wrapper">
      <h3>
        {selectedMob?.name}, {selectedMob?.trip}
      </h3>
      {selectedMob?.slots?.map((slot) => (
        <div className="mob-infos-wrapper__slot-wrapper">
          <span>
            <span className="mob-infos-wrapper__slot-wrapper__bold">
              {t('views.mob_infos.slot_name')}
            </span>{' '}
            {slot.name}
          </span>
          <span>
            <span className="mob-infos-wrapper__slot-wrapper__bold">
              {t('views.mob_infos.package_name')}
            </span>{' '}
            {slot.package}
          </span>
          <span>
            <span className="mob-infos-wrapper__slot-wrapper__bold">Box:</span>{' '}
            {slot.box}
          </span>
          <span>
            <span className="mob-infos-wrapper__slot-wrapper__bold">
              {t('views.mob_infos.status')}
            </span>{' '}
            {slot.status}
          </span>

          <div className="mob-infos-wrapper__slot-wrapper__groceries">
            <span className="mob-infos-wrapper__slot-wrapper__groceries__bold">
              {t('views.mob_infos.groceries')}
            </span>

            {slot.groceries?.map((grocery) => (
              <div className="mob-infos-wrapper__slot-wrapper__groceries__item-wrapper">
                <span>
                  {grocery.quantity} x {grocery.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MobInfos;
