import api from 'axios';
import { APP_VERSION } from '@/views/SignIn/SignInPage/SignInPage';

const user = localStorage.getItem('user') || '{}';
const FR_FR_KEY = 'fr-FR';

const { name } = JSON.parse(user);

export const isProd =
  !window.location.href.includes('staging') &&
  !window.location.href.includes('http://');

export const BASE_URL = isProd
  ? `${process.env.REACT_APP_FAIT_MAISON_API_PRODUCTION}/internal`
  : `${process.env.REACT_APP_FAIT_MAISON_API_STAGING}/internal`;

export const BASE_URL_NON_INTERNAL = isProd
  ? `${process.env.REACT_APP_FAIT_MAISON_API_PRODUCTION}`
  : `${process.env.REACT_APP_FAIT_MAISON_API_STAGING}`;

export const axiosNonInternal = api.create({
  baseURL: BASE_URL_NON_INTERNAL
});

export const axios = api.create({
  baseURL: BASE_URL
});

export const engine = isProd
  ? 'https://api.engine.benebono.co'
  : 'http://localhost';

axios.interceptors.request.use((config) => {
  config.params = {
    // add your default ones
    user: name,
    version: APP_VERSION,
    // spread the request's params
    ...config.params
  };

  const KEY = isProd
    ? process.env.REACT_APP_API_KEY_PRODUCTION
    : process.env.REACT_APP_API_KEY_STAGING;

  config.headers.Authorization = `Bearer ${KEY}`;

  return config;
});

const lng = localStorage.getItem('lng') || FR_FR_KEY;

const isSpain = lng === 'es-ES';

let BASE_URL_HORREO;

if (isProd) {
  BASE_URL_HORREO = isSpain
    ? process.env.REACT_APP_HORREO_API_SPAIN
    : process.env.REACT_APP_HORREO_API;
} else {
  BASE_URL_HORREO = isSpain
    ? process.env.REACT_APP_HORREO_API_SPAIN_STAGING
    : process.env.REACT_APP_HORREO_API_STAGING;
}

export const axiosHorreo = api.create({
  baseURL: BASE_URL_HORREO
});

axiosHorreo.interceptors.request.use((config) => {
  config.data = {
    // add your default ones
    username: isSpain
      ? process.env.REACT_APP_HORREO_USER_NAME_SPAIN
      : process.env.REACT_APP_HORREO_USER_NAME,
    token: isSpain
      ? process.env.REACT_APP_HORREO_TOKEN_SPAIN
      : process.env.REACT_APP_HORREO_TOKEN,
    // spread the request's params
    ...config.data
  };

  config.headers = {
    'Content-Type': 'application/json',
    ...config.headers
  };

  return config;
});

let BASE_URL_OPENAPI;

if (isProd) {
  BASE_URL_OPENAPI = isSpain
    ? process.env.REACT_APP_OPENAPI_HORREO_SPAIN
    : process.env.REACT_APP_OPENAPI_HORREO;
} else {
  BASE_URL_OPENAPI = isSpain
    ? process.env.REACT_APP_OPENAPI_HORREO_STAGING_SPAIN
    : process.env.REACT_APP_OPENAPI_HORREO_STAGING;
}

export const openApiHorreo = api.create({
  baseURL: BASE_URL_OPENAPI
});

openApiHorreo.interceptors.request.use((config) => {
  let username;
  let password;

  if (isSpain) {
    username = process.env.REACT_APP_OPENAPI_HORREO_SPAIN_USER_NAME;
    password = process.env.REACT_APP_OPENAPI_KEY_SPAIN;
  } else {
    username = process.env.REACT_APP_OPENAPI_HORREO_USER_NAME;
    if (isProd) {
      password = process.env.REACT_APP_OPENAPI_KEY;
    } else {
      password = process.env.REACT_APP_OPENAPI_KEY_STAGING;
    }
  }

  config.auth = {
    username,
    password
  };

  config.headers = {
    'Content-Type': 'application/json',
    ...config.headers
  };

  return config;
});

axiosHorreo.interceptors.request.use((request) => {
  const KEY = isProd
    ? process.env.REACT_APP_API_KEY_PRODUCTION
    : process.env.REACT_APP_API_KEY_STAGING;

  fetch(`${BASE_URL}/picking/logs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${KEY}`
    },
    body: JSON.stringify({
      url: request.url,
      method: request.method,
      user: name,
      payload: request.data || {},
      severity: 'request'
    })
  });

  console.log(request);

  return request;
});

axiosHorreo.interceptors.response.use((response) => {
  const KEY = isProd
    ? process.env.REACT_APP_API_KEY_PRODUCTION
    : process.env.REACT_APP_API_KEY_STAGING;

  fetch(`${BASE_URL}/picking/logs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${KEY}`
    },
    body: JSON.stringify({
      url: response.config.url,
      method: response.config.method,
      user: name,
      payload: response.data || {},
      severity: 'response',
      comment: response?.data?.result?.error_message
    })
  });
  return response;
});
