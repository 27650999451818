/* eslint-disable no-param-reassign */
import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import {
  getProductsList,
  updateStationProducts
} from './missingProducts.actions';

const initialState = {
  data: [],
  error: null,
  productUpdated: null
};

const missingProducts = createSlice({
  name: 'missingProducts',
  initialState,
  reducers: {
    resetProductsList: (state) => {
      state.data = [];
      state.error = null;
      state.productUpdated = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProductsList.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(getProductsList.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.data = payload;
      })
      .addCase(getProductsList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateStationProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.productUpdated = payload;
      });
  }
});

export default missingProducts.reducer;
