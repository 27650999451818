/* eslint-disable no-param-reassign */
import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import { sortProductsByLocation } from '@/helpers/helpers';
import {
  bookMobileRack,
  cancelPreviousAction,
  fetchSupplyProducts,
  processProductScan
} from './supplyProducts.actions';

const initialState = {
  data: [],
  productToScan: {},
  previousScanned: {},
  totalScanned: 0,
  totalToScan: 0,
  missingProducts: 0,

  mobileRack: null,

  status: 'idle',
  error: null
};

const supplyProductsSlice = createSlice({
  name: 'supplyProducts',
  initialState,
  reducers: {
    getSupplyProducts: (state, { payload }) => {
      console.log(payload);
      const products = [...payload];
      const sortedProducts = sortProductsByLocation(products);
      state.data = sortedProducts;
      console.log({ sortedProducts });
      // eslint-disable-next-line prefer-destructuring
      state.productToScan = sortedProducts[0];
      state.totalToScan = state.data.reduce((prev, curr) => prev + curr.qty, 0);
      state.status = 'succeeded';
    },
    processValidProductScan: (state, { payload }) => {},
    cancelPreviousProductScan: (state, { payload }) => {
      const warning = payload;
      state.productToScan = state.previousScanned;
      const previousScannedIndex = state.data.findIndex(
        (p) => p.shopify_id === state.previousScanned.shopify_id
      );

      if (warning) {
        // Cancel scan d'un produit manquant
        state.missingProducts -= state.previousScanned.qty;
        state.data.unshift(state.previousScanned);
      } else {
        state.totalScanned -= 1;

        if (previousScannedIndex !== -1) {
          console.log('product still there for another scan');
          state.data[previousScannedIndex].qty += 1;
        } else {
          console.log('last one to scan, aawed jibou');
          state.data.unshift({ ...state.previousScanned, qty: 1 });
        }
      }
      state.previousScanned = {};
    },
    resetSupplyProductsData: (state) => {
      state.productToScan = {};
      state.previousScanned = {};
    },
    setMobileRack: (state, { payload }) => {
      console.log('setmobilerack payload', payload);
      state.mobileRack = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(bookMobileRack.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(bookMobileRack.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
      })
      .addCase(bookMobileRack.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchSupplyProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSupplyProducts.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.error = null;
        console.log('fetchsupply products fullfilled payload');
        console.table({ payload });
        state.data = payload;
        // eslint-disable-next-line prefer-destructuring
        state.productToScan =
          payload.find((p) => p.desiredQty !== p.currentQty + p.missingQty) ||
          {};
        state.totalToScan = state.data.reduce(
          (prev, curr) => prev + curr.desiredQty,
          0
        );
        state.totalScanned = state.data.reduce(
          (prev, curr) => prev + curr.currentQty,
          0
        );
        state.missingProducts = state.data.reduce(
          (prev, curr) => prev + curr.missingQty,
          0
        );
      })
      .addCase(fetchSupplyProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(processProductScan.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(processProductScan.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';

        state.previousScanned = payload;
      })
      .addCase(processProductScan.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(cancelPreviousAction.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(cancelPreviousAction.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        // state.productToScan = payload;
        state.previousScanned = {};
      })
      .addCase(cancelPreviousAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

function selectProducts(state) {
  return state.supplyProducts.data;
}

export const selectSupplyTotalProducts = createSelector(
  selectProducts,
  (products) => products.reduce((prev, curr) => prev + curr.qty, 0)
);

export const {
  getSupplyProducts,
  processValidProductScan,
  cancelPreviousProductScan,
  resetSupplyProductsData,
  setMobileRack
} = supplyProductsSlice.actions;

export default supplyProductsSlice.reducer;
