import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@/components';
import { setMode, MODES } from '@/redux/mode/mode.slice';
import { selectUser } from '@/redux/user/user.slice';
import { getBatchs } from '@/redux/restock/restock.actions';
import { axios } from '@/helpers/api';

const SelectMode = () => {
  const { t } = useTranslation();

  const { id: userID, hubId } = useSelector(selectUser);
  const [filteredModes, setFilteredModes] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMode({}));
  }, []);

  const handleModeSelection = (mode) => {
    window.location.hash = mode.id;
    dispatch(setMode(mode));
  };

  const getModesPerHub = async () => {
    const res = await axios.get(`/picking/hub/${hubId}`);
    return res;
  };

  const fetchModes = async () => {
    if (userID) {
      try {
        const result = await getModesPerHub();
        const filteredModesTmp = MODES.filter((mode) =>
          result.data.internal_features.includes(mode.name)
        );

        setFilteredModes(filteredModesTmp);
        console.log({ filteredModesTmp });
      } catch (error) {
        console.error('Failed to fetch modes per hub:', error);
        setFilteredModes(null);
      }
    }
  };

  useEffect(() => {
    if (userID) dispatch(getBatchs(userID));
  }, [userID]);

  useEffect(() => {
    const intervalId = setInterval(fetchModes, 20000);
    fetchModes();

    return () => clearInterval(intervalId);
  }, [userID]);

  return (
    <div className="v-select-mode u-page-center">
      <div className="v-select-mode__title">{t('views.select_mode.title')}</div>
      <div className="c-select-mode__content">
        {filteredModes?.map(
          (option) =>
            option.isVisible && (
              <SelectOption
                key={option.id}
                option={option}
                link={option.id}
                name={t(`views.select_mode.${option.name}`)}
                onSelect={handleModeSelection}
              />
            )
        )}
      </div>
    </div>
  );
};

export default SelectMode;
