import { createSlice } from '@reduxjs/toolkit';
import { shipBox } from './shipBox.actions';

const initialState = {
  status: '',
  message: '',
  packagesNumber: null,
  missingFruitsAndVegetables: []
};

const shipBoxSlice = createSlice({
  name: 'shipBox',
  initialState,
  reducers: {
    resetShipBox: (state) => {
      state.status = '';
      state.message = '';
      state.packagesNumber = null;
      state.missingFruitsAndVegetables = [];
    },
    setmissingFruitsAndVegetables: (state, action) => {
      state.missingFruitsAndVegetables = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(shipBox.fulfilled, (state, { payload }) => {
        state.status = payload.status;
        state.packagesNumber = payload.data.packagesNumber;
        state.missingFruitsAndVegetables =
          payload.data.missingFruitsAndVegetables;
      })
      .addCase(shipBox.rejected, (state, { payload }) => {
        state.message = payload.data.message;
        state.status = payload.status;
        state.packagesNumber = payload.data.packagesNumber;
        state.missingFruitsAndVegetables =
          payload.data.missingFruitsAndVegetables;
      });
  }
});

export const { resetShipBox, setmissingFruitsAndVegetables } =
  shipBoxSlice.actions;

export default shipBoxSlice.reducer;
