import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { inspectMob } from '@/redux/inspectMob/inspectMob.actions';
import { setMob } from '@/redux/inspectMob/inspectMob.slice';

function InspectMob() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.inspectMob);

  useEffect(() => {
    if (!data) {
      dispatch(inspectMob());
    }
  }, [data]);

  return (
    <div className="inspect-mob-wrapper">
      {data?.map((mob) => (
        <Link to={mob.name} onClick={() => dispatch(setMob(mob))}>
          {mob.name}
          <span className="inspect-mob-wrapper__pr">{mob.trip}</span>
        </Link>
      ))}
    </div>
  );
}

export default InspectMob;
