import { configureStore } from '@reduxjs/toolkit';

// Combined reducer
import userReducer from './user/user.slice';
import modeReducer from './mode/mode.slice';
import modalReducer from './modal/modal.slice';
import roundReducer from './round/round.slice';
import barcodeReducer from './barcode/barcode.slice';
import supplyProductsReducer from './supplyProducts/supplyProducts.slice';
import fillProductsReducer from './fillProducts/fillProducts.slice';
import basketReducer from './baskets/baskets.slice';
import restockReducer from './restock/restock.slice';
import warehouseFlowsReducer from './warehouseFlows/warehouseFlows.slice';
import pickingReducer from './picking/picking.slice';
import fillv2Reducer from './fillv2/fillv2.slice';
import printLabelsFcReducer from './printLabelsFc/printLabelsFc.slice';
import missingProductsReducer from './missingProducts/missingProducts.slice';
import shipBoxReducer from './shipBox/shipBox.slice';
import inspectMobReducer from './inspectMob/inspectMob.slice';
import miniboardReducer from './miniboard/miniboard.slice';

const reducer = {
  user: userReducer,
  mode: modeReducer,
  modal: modalReducer,
  round: roundReducer,
  barcode: barcodeReducer,
  basket: basketReducer,
  supplyProducts: supplyProductsReducer,
  fillProducts: fillProductsReducer,
  restock: restockReducer,
  warehouseFlows: warehouseFlowsReducer,
  picking: pickingReducer,
  fillv2: fillv2Reducer,
  printLabelsFc: printLabelsFcReducer,
  missingProducts: missingProductsReducer,
  shipBox: shipBoxReducer,
  inspectMob: inspectMobReducer,
  miniboard: miniboardReducer
};

// Store configuration

export default configureStore({
  reducer
});
