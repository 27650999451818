import React from 'react';
import { useTranslation } from 'react-i18next';

function ErrorboundaryLayout() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}
    >
      <h1>{t('views.errorBoundary.something_went_wrong')}</h1>
      <div
        style={{
          border: '1px solid black',
          padding: '1em',
          borderRadius: '10px'
        }}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        {t('views.errorBoundary.reload')}
      </div>
    </div>
  );
}

export default ErrorboundaryLayout;
