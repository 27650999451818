import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/helpers/api';
import hubs from '@/constants/hubs';

export const getUpcomingTripsList = createAsyncThunk(
  'printLabelsFc/getUpcomingTripsList',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    const hubName = hubs.find(
      (hub) => hub.id === state.user.data.hubId
    ).fullname;

    try {
      const { data } = await axios.get(`/warehouses/${hubName}/upcomingTrips`);

      return data;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.response.status);
    }
  }
);

export const getTrip = createAsyncThunk(
  'printLabelsFc/getTrip',
  async (tripId, { getState, rejectWithValue }) => {
    const state = getState();

    const hubName = hubs.find(
      (hub) => hub.id === state.user.data.hubId
    ).fullname;

    try {
      const { data } = await axios.get(
        `/warehouses/${hubName}/trips/${tripId}`
      );

      return data;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.response.status);
    }
  }
);

export const getLabels = createAsyncThunk(
  'printLabelsFc/getLabels',
  async ({ barCode, boxId }, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/picking/trips/-/boxes/${boxId}/labels?barCode=${
          barCode !== '' ? barCode : 'N/A'
        }`
      );

      return data;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(error.response.status);
    }
  }
);
