import { Component } from 'react';
import ErrorboundaryLayout from './components/ErrorboundaryLayout/ErrorboundaryLayout';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorboundaryLayout />;
    }

    return children;
  }
}

export default ErrorBoundary;
