import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PenIcong from '@/assets/pen_icon.png';
import { Modal } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';
import {
  getProductsList,
  updateStationProducts
} from '@/redux/missingProducts/missingProducts.actions';
import { selectUser } from '@/redux/user/user.slice';

function MissingProduct() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, productUpdated } = useSelector(
    (state) => state.missingProducts
  );
  const { hubId } = useSelector(selectUser);
  const [sortedProductsLineA, setSortedProductsLineA] = useState([]);

  useEffect(() => {
    dispatch(getProductsList(hubId));
  }, [productUpdated]);

  const handleAvailabilityUpdate = (product, availabilityUpdate) => {
    const payload = {
      id: parseInt(product.slot),
      product: {
        availability:
          availabilityUpdate === 'AVAILABLE' ? 'MISSING' : 'AVAILABLE'
      },
      mask: 'product.availability'
    };
    dispatch(
      updateStationProducts({
        hubId,
        lineId: product.line,
        stationId: product.station,
        slotId: product.slot,
        payload
      })
    );
    dispatch(getProductsList(hubId));
    dispatch(toggleModal(false));
  };

  const getProductsByLine = () => {
    const productsByLine = {
      lineA: [],
      lineB: []
    };

    data.lines.forEach((line) => {
      if (line.id === 'A' || line.id === 'B') {
        line.stations.forEach((station) => {
          station.slots.forEach((slot) => {
            const { product } = slot;
            if (line.id === 'A') {
              productsByLine.lineA.push({
                product,
                line: 'A',
                station: station.id,
                slot: slot.id
              });
            } else if (line.id === 'B') {
              productsByLine.lineB.push({
                product,
                line: 'B',
                station: station.id,
                slot: slot.id
              });
            }
          });
        });
      }
    });

    return productsByLine;
  };

  useEffect(() => {
    if (data.id !== undefined) {
      const productsToSort = getProductsByLine();
      const sorted = productsToSort?.lineA.sort((a) =>
        a.availability === 'AVAILABLE' ? -1 : 1
      );
      setSortedProductsLineA(sorted);
    }
  }, [data]);

  const [currentProduct, setCurrentProduct] = useState(null);

  const modalContent = () => (
    <div>
      <div className="missing-product-modal">
        {currentProduct?.product.conditioning.alias}{' '}
        {currentProduct?.product.availability === 'AVAILABLE'
          ? t('views.missing_product.missing')
          : t('views.missing_product.available')}{' '}
        ?
      </div>
      <div className="missing-product-modal__buttons">
        <div
          className="missing-product-modal__buttons__cancel"
          onClick={() => dispatch(toggleModal(false))}
        >
          {t('views.missing_product.no')}
        </div>
        <div
          className={`missing-product-modal__buttons__confirm ${
            currentProduct?.product.availability === 'AVAILABLE'
              ? 'available'
              : 'missing'
          }`}
          onClick={() =>
            handleAvailabilityUpdate(
              currentProduct,
              currentProduct.product.availability
            )
          }
        >
          {t('views.missing_product.yes')}
        </div>
      </div>
    </div>
  );

  return (
    <div className="missing-product-wrapper">
      <div className="missing-product-wrapper__banner">
        {t('views.missing_product.stock_state')}
      </div>
      <div className="missing-product-wrapper__header">
        <div className="missing-product-wrapper__header__infos-container">
          <span>{t('views.missing_product.product')}</span>
          <span className="missing-product-wrapper__header__infos-container__state">
            {t('views.missing_product.status')}
          </span>
        </div>
        <span>{t('views.missing_product.edit')}</span>
      </div>
      {sortedProductsLineA?.map((item, index) => (
        <div className="missing-product-wrapper__row">
          <div className="missing-product-wrapper__row__infos-container">
            <div className="missing-product-wrapper__row__infos-container__name">
              {item.product.conditioning.alias}
            </div>
            <div className="missing-product-wrapper__row__infos-container__state">
              <div
                className={`missing-product-wrapper__row__infos-container__state__icon ${
                  item.product.availability === 'AVAILABLE'
                    ? 'available'
                    : 'missing'
                }`}
              />
              <span
                className={`missing-product-wrapper__row__infos-container__state__${item.product.state}`}
              >
                {item.product.availability === 'AVAILABLE'
                  ? 'Disponible'
                  : 'Manquant'}
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              setCurrentProduct(item);
              dispatch(toggleModal(true));
            }}
          >
            <img
              className="missing-product-wrapper__row__button"
              src={PenIcong}
              alt="pen icon"
            />
          </div>
        </div>
      ))}
      <Modal>{modalContent()}</Modal>
    </div>
  );
}

export default MissingProduct;
